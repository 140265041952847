import i18n from '@/i18n'
import { IntervalTypes } from '@/modules/reports/store/reportsStore'

export const reportIntervalTypes = [
  {
    label: i18n.t('Weekly'),
    value: IntervalTypes.Week,
  },
  {
    label: i18n.t('Monthly'),
    value: IntervalTypes.Month,
  },
  {
    label: i18n.t('Quarterly'),
    value: IntervalTypes.Quarter,
  },
  {
    label: i18n.t('Yearly'),
    value: IntervalTypes.Year,
  },
  {
    label: i18n.t('All Time'),
    value: IntervalTypes.AllTime,
  },
  {
    label: i18n.t('Custom'),
    value: IntervalTypes.Custom,
  },
]

const reportColors = [
  '#15803d',
  '#0d9488',
  '#0284c7',
  '#6d28d9',
  '#9333ea',
  '#f87171',
  '#c2410c',
  '#d97706',
  '#fb7185',
  '#d1d5db',
]

export function getTaskColor(index: number) {
  if (index >= reportColors.length) {
    return reportColors[reportColors.length - 1]
  }
  return reportColors[index]
}
