import { Component, createApp } from 'vue'
import DeleteDialog from './DeleteDialog.vue'
import NotificationDialog from './NotificationDialog.vue'
import globalPlugins from "@/plugins/globalPlugins";

function cleanup(mountId: string) {
  const el = document.getElementById(mountId)
  if (el) {
    document.body.removeChild(el)
  }
}

type ResolveParams = {
  confirmationText: string,
  extraInputText: string,
}

interface OpenModalParams {
  title?: string,
  type?: string,
  description?: string,
  icon?: Component,
  buttonText?: string,
  buttonType?: string,
  wrapperClass?: string,
  hasInput?: boolean,
  inputLabel?: string,
  inputType?: string,
  onClose?: () => void,
  onResolve?: (params: ResolveParams) => void,
  extraConfirmation?: boolean,
}

export const $deleteConfirm = function openDeleteModal(params: OpenModalParams = {}) {
  return new Promise((resolve, reject) => {
    try {
      const mountId = 'delete-modal'
      const App = createApp(DeleteDialog, {
        open: true,
        title: params.title || '',
        description: params.description || '',
        extraConfirmation: params.extraConfirmation || false,
        onDelete() {
          resolve(true)
          cleanup(mountId)
        },
        onCancel() {
          resolve(false)
          cleanup(mountId)
        },
      })
      App.use(globalPlugins)
      const modalDiv = document.createElement('div')
      modalDiv.id = mountId
      document.body.appendChild(modalDiv)

      App.mount(modalDiv)
    } catch (err) {
      reject(err)
    }
  })
}

export const $confirm = function openModal(params: OpenModalParams = {}) {
  return new Promise((resolve, reject) => {
    try {
      const mountId = 'confirm-modal'
      const App = createApp(DeleteDialog, {
        open: true,
        title: params.title || '',
        description: params.description || '',
        type: 'primary',
        icon: params.icon,
        deleteText: params.buttonText || 'Ok',
        buttonType: params.buttonType || 'error',
        hasInput: params.hasInput,
        inputType: params.inputType,
        inputLabel: params.inputLabel,
        onDelete(data: ResolveParams) {
          resolve(true)
          if (params.onResolve) {
            params.onResolve(data)
          }
        },
        onCancel() {
          resolve(false)
          cleanup(mountId)
        },
      })
      App.use(globalPlugins)
      const modalDiv = document.createElement('div')
      modalDiv.id = mountId
      document.body.appendChild(modalDiv)
      App.mount(`#${mountId}`)
    } catch (err) {
      reject(err)
    }
  })
}

export const $notifyDialog = function openModal(params: OpenModalParams = {}) {
  return new Promise((resolve, reject) => {
    try {
      const mountId = 'notify-dialog'
      const props: any = {
        open: true,
        type: params.type || 'success',
        title: params.title || '',
        description: params.description || '',
        confirmText: params.buttonText || 'Confirm',
        wrapperClass: params.wrapperClass || '',
      }

      if (params.onClose) {
        props.onClose = params.onClose
      } else {
        props.onClose = function onClose() {
          resolve(false)
          cleanup(mountId)
        }
      }

      const App = createApp(NotificationDialog, props)
      App.use(globalPlugins)
      const modalDiv = document.createElement('div')
      modalDiv.id = mountId
      document.body.appendChild(modalDiv)
      App.mount(`#${mountId}`)
    } catch (err) {
      reject(err)
    }
  })
}
