import Mime from "mime/Mime";
import defaultMime from 'mime';
import { Ref } from "vue";
import Data = API.Data;
import Document = App.Domains.Documents.Models.Document;

export function getHumanFileSize(sizeInBytes: number = 0) {
  if (sizeInBytes === 0) {
    return `0B`
  }
  let i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const convertedSize = (sizeInBytes / Math.pow(1024, i)).toFixed(2)
  const sizeMetric = ['B', 'kB', 'MB', 'GB', 'TB'][i]
  return `${convertedSize} ${sizeMetric}`
}

export const fileTypeMap = {
  'Word document': ['doc', 'docx'],
  'Image': ['jpg', 'png', 'gif', 'svg', 'jpeg'],
  'Presentation': ['ppt', 'pptx'],
  'Excel': ['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'xla', 'xlr', 'xlw'],
  'text/csv': ['csv'],
  'Open Document': ['ods'],
  'Pdf Document': ['pdf'],
  'Text file': ['txt']
}

export const fileTypes = {
  Word: 'word document',
  Image: 'image',
  Presentation: 'presentation',
  Excel: 'excel',
  Csv: 'text/csv',
  PdfDocument: 'pdf document',
  Text: 'text file',
  Video: 'video',
  VideoQuickTime: 'video/quicktime',
  Json: 'application/json',
}

export const previewTypes = [
  fileTypes.Presentation,
  fileTypes.Csv,
  fileTypes.PdfDocument,
  fileTypes.Text,
  fileTypes.Video,
  fileTypes.Image,
  fileTypes.Json,
]

export const docTypes = [
  fileTypes.Word,
  fileTypes.Presentation,
  fileTypes.Excel,
  fileTypes.PdfDocument,
]

export const typeColors: { [key: string]: any } = {
  'word document': {
    main: 'bg-blue-500',
    text: 'text-blue-500',
    background: 'text-blue-100',
  },
  'pdf document': {
    main: 'bg-red-500',
    text: 'text-red-500',
    background: 'text-red-100',
  },
  'image': {
    main: 'bg-orange-500',
    text: 'text-orange-500',
    background: 'text-orange-100',
  },
  'excel': {
    main: 'bg-primary',
    text: 'text-primary',
    background: 'text-primary/10',
  },
  'text/csv': {
    main: 'bg-primary',
    text: 'text-primary',
    background: 'text-primary/10',
  },
  default: {
    main: 'bg-gray-500',
    text: 'text-gray-500',
    background: 'text-gray-100',
  },
}

export function getFileTypeColor(pathOrExtension: string, loading = false) {
  if (loading) {
    return typeColors.default
  }
  const type = getFileType(pathOrExtension) || ''
  return typeColors[type] || typeColors.default
}

let myMime = new Mime(fileTypeMap)

export function getFileExtension(fileName: string) {
  if (!fileName) {
    return ''
  }
  const parts = fileName.split('.')
  return parts[parts.length - 1] || ''
}

export function isImage(file: Data<Document> | undefined) {
  if (!file) {
    return false
  }
  const fileType = getFileType(file.attributes?.file_name)
  return fileType === fileTypes.Image
}

export function getFileWithoutExtension(fileName: string) {
  const parts = fileName.split('.')
  return parts.slice(0, parts.length - 1).join('.')
}

export function getFileType(pathOrExtension: string): string | null {
  return myMime.getType(pathOrExtension) || defaultMime.getType(pathOrExtension)
}

export function downloadFile(url: string, fileName: string) {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  if (fileName) {
    link.download = fileName
  }
  link.click()
}

type UpdateProgressParams = {
  file: File | null,
  progress: number,
  fileProgress: Ref,
}
export function updateFileProgress(params: UpdateProgressParams) {
  if (!params.file?.name || !params.file) {
    return
  }
  const fileProgress = params.fileProgress.value[params.file.name]
  if (!fileProgress) {
    params.fileProgress.value = {
      [params.file.name]: {
        progress: 0,
        error: '',
      },
    }
  }
  params.fileProgress.value[params.file.name].progress = Math.round(params.progress)
}

export async function fileToDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const acceptedFileTypes = `.pdf,.docx,.doc,.txt,.csv,.rtf,.jpg,.jpeg,.png,.svg,.gif,.bmp,.xls,.xlsx,.ppt,.pptx`

export function isAcceptedFile(file: File) {
  const acceptedFileTypesArray = acceptedFileTypes.split(',')
  const fileExtension = getFileExtension(file.name)
  return acceptedFileTypesArray.includes(`.${fileExtension}`)
}
