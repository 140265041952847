<template>
  <div class="mt-6">
    <div class="grid grid-cols-2 gap-4 pb-10">
      <EventsCalendar class="col-span-2" />
      <!-- Will be enabled later -->
      <CompanyUpdatesCard v-if="false" class="col-span-2 md:col-span-1" />
      <MyActivityCard class="col-span-2" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuth } from '@/modules/auth/composables/useAuth'
import MyActivityCard from '@/modules/dashboard/components/MyActivityCard.vue'
import CompanyUpdatesCard from '@/modules/dashboard/components/CompanyUpdatesCard.vue'
import EventsCalendar from '@/modules/dashboard/components/calendar/EventsCalendar.vue'

const { can } = useAuth()
</script>

<route lang="yaml">
name: Dashboard
</route>
