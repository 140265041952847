import axios from 'axios'
import { defineStore } from 'pinia'
import { useClipboard } from '@vueuse/core'
import { Invoice, LineItem } from '@/modules/invoices/types/invoiceTypes'
import Cache from '@/modules/common/Cache'
import { incrementInvoiceNumber } from '@/modules/invoices/utils/invoiceUtils'
import { downloadFileLocally } from '@/modules/common/utils/downloadFileLocally'
import Data = API.Data
import i18n from '@/i18n'
import { error, success } from '@/components/common/NotificationPlugin'
import { $confirm } from '@/components/common/modal/modalPlugin'

export const useInvoiceStore = defineStore('invoiceStore', {
  state: () => ({
    currentInvoice: null as Data<Invoice> | null,
    currentInvoiceError: null as any,
    currentInvoiceLoading: false,
    shareLoading: false,
    sendLoading: false,
    markAsPaidLoading: false,
    lastInvoice: null as Data<Invoice> | null,
  }),
  actions: {
    async sendInvoice(email: string, invoiceId: string) {
      try {
        const { data } = await axios.get(`/restify/invoices/${invoiceId}`)
        if (!data.attributes.share_token) {
          await this.shareInvoice(invoiceId, true)
        }
        this.sendLoading = true
        await axios.post(
        `/restify/invoices/${invoiceId}/actions?action=send-invoice`,
        { email },
        )
      } catch (e) {
        error(i18n.t('Could not send the invoice'))
      } finally {
        this.sendLoading = false
      }
    },
    async getLastInvoice() {
      const { data } = await axios.get('/restify/invoices', {
        params: {
          sort: '-created_at',
          perPage: 1,
        },
      })
      if (data.length > 0) {
        this.lastInvoice = data[0]
      }
    },
    async downloadInvoiceAsPdf() {
      const invoiceToken = this.currentInvoice?.attributes?.share_token
      const invoiceName = this.currentInvoice?.attributes?.invoice_number ?? this.currentInvoice?.attributes?.title
      const fileName = `${invoiceName} @ ${new Date().toDateString()}.pdf`

      try {
        const data = await axios.post(
          `/invoices/${invoiceToken}/download`,
          { file_name: fileName },
          {
            responseType: 'blob',
          })
        downloadFileLocally(data, fileName)
      } catch (e) {
        error(i18n.t('Could not download invoice'))
      }
    },
    async createInvoice(requestData: { invoice: any; lineItems: LineItem[] }) {
      const { data } = await axios.post('/restify/invoices', requestData.invoice)
      const invoiceId = data.id
      const lineItems = requestData.lineItems.map(lineItem => ({ ...lineItem, invoice_id: invoiceId }))
      const promises = lineItems.map(lineItem => axios.post('/restify/invoice-lines', lineItem))
      await Promise.all(promises)
      return data
    },
    async updateInvoice(requestData: { invoice: any; lineItems: LineItem[] }) {
      const { data } = await axios.put(`/restify/invoices/${requestData.invoice.id}`, requestData.invoice)
      const invoiceId = data.id
      const lineItemsToUpdate = requestData.lineItems.filter(lineItem => lineItem?.id)
      const lineItemsToAdd = requestData.lineItems
        .filter(lineItem => !lineItem?.id)
        .map(lineItem => ({ ...lineItem, invoice_id: invoiceId }))

      const addPromises = lineItemsToAdd.map(lineItem => axios.post('/restify/invoice-lines', lineItem))
      const updatePromises = lineItemsToUpdate.map(lineItem => axios.put(`/restify/invoice-lines/${lineItem.id}`, lineItem))

      await Promise.all([...addPromises, ...updatePromises])
      await this.getInvoiceById(requestData.invoice.id as string)
    },
    async shareInvoice(invoiceId: string, skipLinkCopy = false) {
      if (!invoiceId) {
        error(i18n.t('Could not share invoice'))
        return
      }
      try {
        this.shareLoading = true
        const { data } = await axios.post(`/restify/invoices/${invoiceId}/actions?action=share-invoice`)
        if (this.currentInvoice) {
          this.currentInvoice.attributes.share_token = data.share_token
        }
        if (skipLinkCopy) {
          return
        }
        const url = `${window.location.origin}/share/invoices/${data.share_token}/details`
        const { copy } = useClipboard({ source: url })
        await copy()
        success(i18n.t('Invoice share link copied to clipboard'))
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('Could not share invoice'))
      } finally {
        this.shareLoading = false
      }
    },
    async markAsPaid(invoiceId: string) {
      try {
        const confirmed = await $confirm({
          title: i18n.t('Mark as paid'),
          description: i18n.t('By confirming, this invoice will be marked as paid and you will not be able to edit it anymore.'),
          buttonType: 'primary',
          buttonText: i18n.t('Mark as paid'),
        })
        if (!confirmed) {
          return
        }
        this.markAsPaidLoading = true
        const { data } = await axios.post(`/restify/invoices/${invoiceId}/actions?action=mark-as-paid`)
        this.currentInvoice!.attributes = {
          ...(this.currentInvoice?.attributes || {}),
          ...(data || {}),
        }
        success(i18n.t('Invoice marked as paid'))
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('Could not mask the invoice as piad'))
      } finally {
        this.markAsPaidLoading = false
      }
    },
    async deleteLineItem(lineItem: LineItem) {
      await axios.delete(`/restify/invoice-lines/${lineItem.id}`)
    },
    async getInvoiceById(id: string): Promise<Data<Invoice>> {
      try {
        this.currentInvoiceLoading = true
        const { data } = await axios.get(`/restify/invoices/${id}`, {
          params: {
            related: 'lineItems,client',
          },
        })
        this.currentInvoice = data
        return data as Data<Invoice>
      } catch (err) {
        this.currentInvoiceError = err
        throw err
      } finally {
        this.currentInvoiceLoading = false
      }
    },
    async getSharableInvoice(shareToken: string): Promise<Data<Invoice>> {
      try {
        this.currentInvoiceLoading = true
        const { data } = await axios.get(`/invoices/${shareToken}`)
        this.currentInvoice = data
        return data as Data<Invoice>
      } catch (err) {
        this.currentInvoiceError = err
        throw err
      } finally {
        this.currentInvoiceLoading = false
      }
    },
  },
  getters: {
    lastInvoiceNumber(state) {
      return state.lastInvoice?.attributes?.invoice_number
    },
    nextInvoiceNumber() {
      const lastInvoiceNumber = this.lastInvoiceNumber || ''
      if (!lastInvoiceNumber) {
        return '001'
      }
      // @ts-expect-error
      return incrementInvoiceNumber(lastInvoiceNumber)
    },
  },
})
