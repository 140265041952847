<template>
  <div class="h-full flex flex-col account-page">
    <PageTitle class="mb-2">
      {{ $t('Google Calendar Integration') }}
    </PageTitle>
    <BaseFormCard
      :show-actions="false"
      class="flex-1"
    >
      <FormCardSection class="h-full">
        <div class="col-span-6 pb-2">
          <IconBox size="sm" class="mb-3">
            <GoogleCalendarIcon class="w-7 h-7"/>
          </IconBox>
          <div class="prose prose-sm mb-6">
            <h5>
              {{
                $t(`You haven't connected any Google Calendar yet. Connecting a Google Calendar account will:`)
              }}
            </h5>
            <ul>
              <li>
                {{ $t('Automatically sync approved holiday requests to your Google Calendar.') }}
              </li>
              <li>
                {{ $t('Automatically sync rejected holiday requests to your Google Calendar.') }}
              </li>
            </ul>
          </div>
          <BaseButton
            v-if="!isCalendarConnected"
            outline
            variant="primary"
            :loading="calendarsDataLoading"
            @click="onConnect"
          >
            <GoogleCalendarIcon class="w-5 h-5 mr-2"/>
            <span>{{ $t('Connect Google Calendar') }}</span>
          </BaseButton>
          <BaseButton
            v-else
            outline
            variant="primary"
            @click="disconnectCalendar"
          >
            <GoogleCalendarIcon class="w-5 h-5 mr-2"/>
            <span>{{ $t('Disconnect Google Calendar') }}</span>
          </BaseButton>

          <div v-if="isCalendarConnected" class="max-w-md">
            <FormKit
              :model-value="selectedCalendar"
              type="googleCalendarSelect"
              :label="$t('Calendar to sync with')"
              :placeholder="$t('Choose calendar to sync with')"
              :provider="Providers.GoogleTenant"
              class="col-span-3"
              @update:model-value="connect"
            />
          </div>
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import GoogleCalendarIcon from "@/modules/settings/components/icons/GoogleCalendarIcon.vue"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { Providers, useCalendarIntegration } from "@/modules/settings/composables/useCalendarIntegration";

const {
  calendarsDataLoading,
  selectedCalendar,
  isCalendarConnected,
  onConnect,
  connect,
  disconnect,
} = useCalendarIntegration(Providers.GoogleTenant)

const { t } = useI18n()

async function disconnectCalendar() {
  const confirmed = await $confirm({
    title: t('Are you sure you want to disconnect Google Calendar?'),
    description: t('This will stop showing Google Calendar events in your Growee Calendar.'),
    buttonText: t('Disconnect'),
  })
  if (!confirmed) {
    return
  }
  await disconnect()
}
</script>

<route lang="yaml">
name: Google Calendar Integration
</route>
