import ObjectValues = API.ObjectValues;
import i18n from '@/i18n';

export enum RegisterSteps {
  EmailSetup = 'registration',
  ConfirmEmail = 'validation',
  CompanySetup = 'company',
  TeamSetup = 'team',
}

export const Roles = {
  Admin: 'admin',
  Manager: 'manager',
  HR: 'hr',
  Employee: 'employee',
} as const;

export const SettingKeys = {
  GoogleCalendarId: 'holiday_google_calendar_id',
  PersonalGoogleCalendarId: 'google_calendar_id',
  GoogleCalendarRefreshToken: 'holiday_google_calendar_refresh_token',
  PersonalGoogleCalendarRefreshToken: 'google_calendar_refresh_token',
  IncludeHolidayWeekends: 'holiday_include_weekends',
  SlackIncomingHook: 'slack_webhook_url',
  InvoiceCompanyAddress: 'invoice_company_address',
  InvoiceLogo: 'invoice_logo',
  ShowTimesheetDates: 'show_time_sheet_dates',
  CompanyName: 'company_name',
  CompanyIdentificationNumber: 'company_identification_number',
  CompanyRegNumber: 'company_registration_number',
  CompanyStreet: 'company_street_address',
  CompanyCity: 'company_city',
  CompanyState: 'company_county',
  CompanyAdmin: 'company_administrator',
  CompanyEmail: 'company_email',
} as const

export type SettingsKeyType = ObjectValues<typeof SettingKeys>;
export const HolidayStatus = {
  Approved: i18n.t('Approved'),
  Rejected: i18n.t('Rejected'),
  Canceled: i18n.t('Cancelled'),
  Pending: i18n.t('Pending'),
}
