<template>
  <BaseFormDialog
    v-bind="$attrs"
    :title="$t('Send invoice')"
    :submit-text="$t('Send')"
    :loading="invoiceStore.sendLoading"
    @submit="onSubmit"
  >
    <FormKit
      v-model="email"
      outer-class="col-span-6"
      :label="$t('Email')"
      validation="required|email"
    />
  </BaseFormDialog>
</template>

<script setup>
import { ref } from 'vue'
import BaseFormDialog from "@/components/form/BaseFormDialog.vue"
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import { success } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"
const props = defineProps({
  invoiceId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['submit'])

const invoiceStore = useInvoiceStore()

const email = ref('')
async function onSubmit() {
  try {
    await invoiceStore.sendInvoice(email.value, props.invoiceId)
    emit('submit')
    success(i18n.t('Invoice was sent to the provided email'))
  } catch (e) {
    console.warn(e)
  }
}
</script>

