<template>
  <div class="py-12 px-4 lg:px-0 flex flex-col items-center welcome-setup">
    <div class="text-center max-w-xl">
      <h1>
        {{ $t('Welcome, ') }} {{ profile.first_name || profile.email }}
      </h1>
      <p class="mt-2 text-base-300">
        {{ $t('Welcome message') }}
      </p>
    </div>
    <FormKit
      v-slot="{ state: { loading } }"
      :actions="false"
      type="form"
      form-class="w-full flex flex-col items-center"
      @submit="onSubmit"
    >
      <div class="max-w-5xl lg:w-full flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:justify-between mt-16">
        <div>
          <IconBox>
            <UsersIcon class="w-5 h-5" />
          </IconBox>
          <h3 class="my-4">
            {{ $t('About your team') }}
          </h3>
          <h5>{{ $t('How big is your company?') }}</h5>
          <div class="mb-4 text-base-300 text-sm">
            {{ $t('Select an option') }}
          </div>
          <FormKit
            v-model="model.team_size"
            type="radio"
            :name="$t('Team size')"
            :options="companySizeOptions"
            validation="required"
            validation-visiblity="dirty"
          />

          <div class="mt-12">
            <IconBox>
              <BriefcaseIcon class="w-5 h-5" />
            </IconBox>
            <h3 class="my-4">
              {{ $t('Company Industry') }}
            </h3>
            <h5>{{ $t('What type of services is your company delivering ?') }}</h5>
            <div class="mb-4 text-base-300 text-sm">
              {{ $t('Select an option') }}
            </div>
            <FormKit
              v-model="model.industry_id"
              type="industrySelect"
              filterable
              :name="$t('Industry')"
              :placeholder="$t('Choose an industry')"
              validation="required"
              validation-visiblity="dirty"
            />
          </div>
        </div>
        <div>
          <IconBox>
            <MagnifyingGlassIcon class="w-5 h-5" />
          </IconBox>
          <h3 class="my-4">
            {{ $t('How did you find us ?') }}
          </h3>
          <h5>{{ $t('Tell us how you discovered Growee') }}</h5>
          <div class="mb-4 text-base-300 text-sm">
            {{ $t('Select an option') }}
          </div>
          <FormKit
            v-model="model.referrer_source"
            type="radio"
            :name="$t('Referral source')"
            :options="findUsOptions"
            validation="required"
            validation-visiblity="dirty"
          />
          <FormKit
            v-if="model.referrer_source === 'Other'"
            v-model="model.referrer_source_text"
            type="text"
            :label="$t('Other source')"
          />
          <template v-if="false">
            <!-- This feature is not ready yet. The intention was to refer other people here not to invite them -->
            <div class="mt-12">
              <IconBox>
                <UserPlusIcon class="w-5 h-5" />
              </IconBox>
            </div>
            <h3 class="my-4">
              {{ $t('Invite people on Growee') }}
            </h3>
            <h5>{{ $t('Enter the emails of whom do you want to use Growee!') }}</h5>
            <div class="mb-4 text-base-300 text-sm">
              {{ $t('Email') }}
            </div>
            <div class="flex w-full">
              <div class="flex-1">
                <FormKit
                  v-model="currentInvite"
                  type="email"
                  :placeholder="$t(`Enter a colleague's email`)"
                  validation="email"
                  validation-visibility="dirty"
                  @keydown.enter.prevent="addInvite(currentInvite)"
                />
              </div>
              <div>
                <button
                  class="btn btn-outline bg-primary/10 btn-primary ml-1"
                  type="button"
                  @click="addInvite(currentInvite)"
                >
                  <div class="flex items-center px-2">
                    <span>{{ $t('Add') }}</span>
                    <CheckIcon class="w-4 ml-1" />
                  </div>
                </button>
              </div>
            </div>
            <EmailTags
              :tags="model.invitations"
              class="mt-2"
              @close="removeInvite"
            />
          </template>
        </div>
      </div>

      <div class="w-full flex justify-center mt-20">
        <FormKit
          type="submit"
          :disabled="loading"
          :classes="{ input: 'w-full' }"
        >
          <span>{{ $t('Submit & Sign Up') }}</span>
        </FormKit>
      </div>
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { BriefcaseIcon, CheckIcon, MagnifyingGlassIcon, UserPlusIcon, UsersIcon } from '@heroicons/vue/24/outline'
import { reactive, ref } from "vue"
import { storeToRefs } from "pinia"
import { uniq } from "lodash-es"
import { useI18n } from "vue-i18n"
import { RegisterSteps } from "@/modules/auth/types/enums"
import RegisterStep4 = Auth.RegisterStep4
import { useAuthStore } from "@/modules/auth/store/authStore"
import IconBox from '@/components/common/IconBox.vue'
import { error } from "@/components/common/NotificationPlugin"
import router from "@/router/router"
import EmailTags from "@/modules/auth/components/EmailTags.vue"
import { isValidEmail } from "@/modules/common/utils/isValidEmail"

const { t } = useI18n()
const authStore = useAuthStore()
const { profile } = storeToRefs(authStore)

authStore.getProfile()

const currentInvite = ref('')

const model: RegisterStep4 = reactive({
  team_size: '',
  referrer_source: '',
  referrer_source_text: '',
  industry_id: '',
  invitations: [],
  onboarding_step: RegisterSteps.TeamSetup,
  onboarding_token: '',
})

const companySizeOptions = [
  t('Solo'),
  t('1-20 employees'),
  t('20-50 employees'),
  t('50-100 employees'),
  t('100-200 employees'),
  t('200+ employees'),
]

const findUsOptions = [
  'Google',
  'Referral',
  'Social Media',
  'Gitex 2023',
  'Other',
]

function removeInvite(index: number) {
  model.invitations.splice(index, 1)
}

function addInvite(value: string) {
  if (!isValidEmail(value)) {
    return
  }
  model.invitations.push(value)
  model.invitations = uniq(model.invitations)
  currentInvite.value = ''
}

async function onSubmit() {
  try {
    model.onboarding_token = profile.value?.onboarding_token || ''
    const data = {
      ...model,
    }
    if (model.referrer_source_text) {
      data.referrer_source = model.referrer_source_text
    }
    await authStore.registerStep4(model)
    await router.push('/dashboard')
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }

}
</script>

<route lang="yaml">
name: Welcome Setup
meta:
  layout: emptyLayout
</route>

<style>
.welcome-setup .el-select .el-input {
  background: transparent;
}
</style>
