<template>
  <div
    :class="{
      'w-full': !isDialog,
    }"
    class="max-h-[calc(100vh-110px)]"
  >
    <FormKit
      :id="formId"
      v-slot="{ state: { loading, dirty } }"
      :actions="false"
      :disabled="disabled"
      :model-value="value"
      type="form"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <ElDialog
        v-if="props.isDialog"
        v-bind="$attrs"
        ref="dialog"
        @close="emit('close')"
      >
        <slot />

        <template #footer>
          <div class="w-full flex justify-center form-footer">
            <slot name="footer-before" />
            <div class="flex items-center space-x-2">
              <FormKit
                type="button"
                tabindex="-1"
                :classes="{ input: 'min-w-[128px] btn-outline' }"
                @click="onCancel(true)"
              >
                <span>{{ cancelText || t('Cancel') }}</span>
              </FormKit>
              <FormKit
                type="submit"
                :classes="{ input: 'min-w-[128px]' }"
                :disabled="loading || disabled"
              >
                <span>{{ submitText || t('Create') }}</span>
              </FormKit>
            </div>
          </div>
        </template>
      </ElDialog>
      <slot v-else />

      <div
        v-if="!isDialog && showActions"
        class="bg-white z-10 sticky bottom-0 border border-base-200 rounded-sm px-6 py-3 mt-1"
      >
        <FormKitMessages class="mb-2 text-center" />
        <div class="w-full flex justify-end space-x-2">
          <FormKit
            type="button"
            :classes="{ input: 'min-w-[128px] btn-outline' }"
            data-test="cancel_form_button"
            @click="onCancel"
          >
            <span>{{ cancelText || t('Cancel') }}</span>
          </FormKit>
          <FormKit
            type="submit"
            :classes="{ input: 'min-w-[128px] relative' }"
            :disabled="loading || disabled"
            data-test="submit_form_button"
          >
            <span
              :class="{
                'opacity-0': loading || disabled,
              }"
            >
              {{ submitText || t('Create') }}
            </span>
            <div v-if="loading || disabled" class="absolute inset-0 flex justify-center items-center">
              <LoadingCircle class="w-5 h-5 text-primary" />
            </div>
          </FormKit>
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { reset } from '@formkit/core'

import { FormKitMessages } from '@formkit/vue'
import LoadingCircle from "@/components/form/LoadingCircle.vue";
const props = defineProps({
  submitText: {
    type: String,
  },
  cancelText: {
    type: String,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  showActions: {
    type: Boolean,
    default: true,
  },
  disabled: Boolean,
  value: {
    type: [Object, String],
  },
  resetOnCancel: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['submit', 'close', 'cancel', 'update:modelValue'])
const { t } = useI18n()
async function onSubmit() {
  emit('submit')
}

const formId = ref(`base-form-${new Date().getTime()}`)

const dialog = ref()
const form = ref()
function onCancel(cancelClicked = false) {
  if (props.isDialog) {
    dialog.value?.close?.()
  }
  reset(formId.value)
  emit('close', cancelClicked)
  emit('cancel', cancelClicked)
}

function resetForm() {
  reset(formId.value)
}
defineExpose({
  resetForm,
})
</script>
