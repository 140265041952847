<template>
  <div
    class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:-mt-56 dialog-wrapper"
    @keydown.esc="onClose"
  >
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-0"
      leave-to-class="opacity-100"
      appear
    >
      <div
        v-if="showModal"
        class="fixed inset-0 top-0 transition-opacity"
      >
        <div
          class="absolute inset-0 bg-gray-500 opacity-75"
          @click="onClose"
        />
      </div>
    </transition>

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      appear
      @after-leave="onCancelFinished"
    >
      <div
        v-if="showModal"
        class="relative bg-white rounded-lg px-4 sm:px-8 py-10 overflow-hidden shadow-xl transform transition-all sm:max-w-2xl sm:w-full max-h-screen overflow-y-auto"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="flex justify-center mb-6">
          <IconBox>
            <component :is="props.icon" class="w-4 h-4 icon-box-icon" />
          </IconBox>
        </div>
        <div class="flex justify-center">
          <div class="text-center flex flex-col items-center">
            <h3 id="modal-headline" class="text-2xl leading-6 font-semibold text-base-content">
              {{ title }}
            </h3>
            <div v-if="description" class="mt-4 max-w-sm">
              <p class="text-sm leading-5 text-base-300" v-html="description"/>
            </div>
            <FormKit
              :actions="false"
              type="form"
              form-class="w-full mt-4"
              @submit="onDeleteClick"
            >
              <FormKit
                v-if="hasInput"
                v-model="extraInputText"
                :type="inputType"
                :label="inputLabel"
                validation="required"
                validation-visibility="dirty"
              />

              <FormKit
                v-if="extraConfirmation"
                v-model="confirmationText"
                type="text"
                :label="$t('Type in DELETE to confirm')"
                :placeholder="$t('Type in DELETE to confirm')"
                validation="required"
                validation-visibility="dirty"
              />
            </FormKit>
            <slot />
          </div>
        </div>
        <div class="mt-8 sm:mt-10 flex justify-center">
          <span class="mt-3 flex space-x-3 w-auto rounded-md shadow-sm">
            <button
              v-focus="400"
              class="btn btn-cancel btn-outline min-w-[80px]"
              data-test="delete_dialog_cancel_button"
              @click="onClose"
            >
              {{ t(cancelText) }}
            </button>
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                class="btn btn-delete text-white min-w-[80px]"
                :class="{
                  'btn-error': buttonType === 'error',
                  'btn-primary': buttonType === 'primary',
                }"
                :disabled="!extraConfirmationValid"
                data-test="delete_dialog_confirm_button"
                @click="onDeleteClick"
              >
                {{ t(deleteText) }}
              </button>
            </span>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { TrashIcon } from "@heroicons/vue/24/outline"
import i18n from '@/i18n'
import IconBox from "@/components/common/IconBox.vue"
import { useI18n } from "vue-i18n";

const props = defineProps({
  open: Boolean,
  title: {
    type: String,
    default: i18n.t('Delete Information'),
  },
  description: {
    type: String,
    default: i18n.t('Are you sure ? This will permanently remove the information you are about to delete.'),
  },
  deleteText: {
    type: String,
    default: i18n.t('Yes, delete'),
  },
  buttonType: {
    type: String,
    default: 'error',
  },
  cancelText: {
    type: String,
    default: i18n.t('Cancel'),
  },
  type: {
    type: String,
    default: 'danger',
  },
  onCancel: {
    type: Function,
    default: () => ({}),
  },
  extraConfirmation: {
    type: Boolean,
    default: false,
  },
  hasInput: {
    type: Boolean,
    default: false,
  },
  inputType: {
    type: String,
    default: 'text',
  },
  inputLabel: {
    type: String,
    default: 'Reason',
  },
  icon: {
    type: Object,
    default: TrashIcon,
  },
})

const { t } = useI18n()
const emit = defineEmits(['delete', 'cancel'])

const showModal = ref(props.open)

const confirmationText = ref('')
const extraInputText = ref('')

const extraConfirmationValid = computed(() => {
  if (!props.extraConfirmation) {
    return true
  }
  return confirmationText.value === 'DELETE'
})
watch(() => props.open, (value) => {
  showModal.value = value
})

function onDeleteClick() {
  showModal.value = false
  emit('delete', {
    confirmationText: confirmationText.value,
    extraInputText: extraInputText.value,
  })
}
function onClose() {
  showModal.value = false
}

function onCancelFinished() {
  emit('cancel', {
    confirmationText: confirmationText.value,
    extraInputText: extraInputText.value,
  })
}
</script>

<script lang="ts">
export default {
  name: 'DeleteDialog',
  inheritAttrs: false,
}
</script>

<style scoped>
.dialog-wrapper {
  z-index: 10001;
}
</style>
