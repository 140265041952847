<template>
  <div
    class="flex items-center space-x-4"
  >
    <div
      :class="{
        'px-3 py-2': size === Sizes.Small,
        'px-4 py-3': size === Sizes.Medium,
        'p-4': size === Sizes.Large,
        'border border-input': variant === 'card',
        'mt-8': isVacation && showInitialBalance,
      }"
      class="flex justify-between rounded group w-full"
    >
      <div
        class="space-x-2 flex items-end text-sm text-base-content min-w-[200px]"
        @click="onClick"
      >
        <component :is="icon" v-if="icon" class="h-4 text-primary" />
        <span
          :class="{
            'group-hover:font-semibold cursor-pointer': !props.readOnly,
          }"
          class="leading-none"
        >
          {{ policy.attributes.name }}
        </span>
      </div>
      <div class="space-x-2 flex items-end text-sm text-base-content">
        <div
          v-for="column in tableData"
          :key="column.header"
          class="w-[90px] md:w-[100px]"
        >
          <div
            v-if="showHeaders && column.header"
            class="w-full mb-6 text-base-300 text-sm -mt-12"
          >
            {{ column.header }}
          </div>
          <div class="w-full flex items-center space-x-2">
            <CheckIcon class="w-4 min-w-[16px] h-4 text-primary" />
            <span>{{ column.value }}</span>
          </div>
        </div>
        <div v-if="!readOnly">
          <ElDropdown trigger="click" placement="bottom-end">
            <span class="el-dropdown-link hover:bg-primary/5">
              <EllipsisHorizontalIcon class="w-5 text-primary" />
            </span>
            <template #dropdown>
              <ElDropdownMenu class="min-w-[150px]">
                <ElDropdownItem
                  v-for="action in rowActions"
                  :key="action.label"
                  @click.native="action.action()"
                >
                  <div class="flex space-x-2 items-center">
                    <component :is="action.icon" class="w-4 h-4 text-primary" />
                    <span class="text-sm">{{ action.label }}</span>
                  </div>
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </div>
      </div>
    </div>
    <div
      v-if="isVacation && showInitialBalance"
      class="policy-initial-balance"
    >
      <FormKit
        :model-value="policyInitialBalance"
        :label="$t('Balance')"
        name="vacation_balance"
        type="number"
        @update:model-value="onBalanceUpdate"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Data = API.Data;
import HolidayPolicy = App.Domains.HolidayPolicies.Models.HolidayPolicy
import { PropType, computed, watch } from "vue"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import i18n from "@/i18n"
import { HolidayTypeIcons } from "@/modules/settings/types/settingTypes"
import { CheckIcon, EllipsisHorizontalIcon, PencilSquareIcon, TrashIcon } from "@heroicons/vue/24/outline"
import { SizeType, Sizes } from "@/modules/documents/types/documentTypes"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { RowAction } from "@/components/table/tableTypes"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
// eslint-disable-next-line import/order
import { differenceInDays } from "date-fns"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin";
import axios from "axios";
import { error } from "@/components/common/NotificationPlugin";
import { canDeleteRow } from "@/components/table/tableUtils";

const props = defineProps({
  policy: {
    type: Object as PropType<Data<HolidayPolicy>>,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<SizeType>,
    default: Sizes.Large,
  },
  variant: {
    type: String as PropType<"table" | "card">,
    default: 'card',
  },
  employeeId: {
    type: String,
  },
  startDate: {
    type: Date,
  },
  showBalance: Boolean,
  showHeaders: Boolean,
  showInitialBalance: Boolean,
})
const settingsStore = useSettingsStore()
const router = useRouter()
const { t } = useI18n()
const employeeStore = useEmployeeStore()

const policyBalance = computed(() => {
  return settingsStore.getHolidayPolicyBalanceObject(props.policy?.id as string, props.employeeId as string)
})

const adjustedInitialBalance = computed(() => {
  const defaultPolicyBalance = props.policy?.attributes.days_per_year as number
  const now = new Date()
  const startDate = props.startDate || now
  const endOfYear = new Date(startDate.getFullYear(), 11, 31)
  if (now.getFullYear() > startDate.getFullYear()) {
    return defaultPolicyBalance
  }
  const daysTillEndOfYear = differenceInDays(endOfYear, startDate)
  const daysInYear = 365
  return Math.round((defaultPolicyBalance / daysInYear) * daysTillEndOfYear)
})

const policyInitialBalance = computed(() => {
  return policyBalance.value?.attributes?.initial_balance || adjustedInitialBalance.value
})
const remainingDays = computed(() => {
  return settingsStore.getHolidayPolicyBalance(props.policy?.id as string, props.employeeId as string)
})

const isUnlimited = computed(() => {
  return !props.policy?.attributes.days_per_year
})

const takenDays = computed(() => {
  const initialBalance = settingsStore.getHolidayPolicyInitialBalance(props.policy?.id as string, props.employeeId as string)
  return initialBalance - remainingDays.value
})

const isPaid = computed(() => {
  return props.policy?.attributes?.paid
})

const isVacation = computed(() => {
  const vacation = settingsStore.getVacationPolicy
  return vacation?.id === props.policy?.id
})

const tableData = computed(() => {
  const policyTotal = props.policy?.attributes.days_per_year
  const columns = [
    {
      header: '',
      value: isPaid.value ? t('Paid') : t('Unpaid'),
    },
    {
      header: t('Total'),
      value: policyTotal || t('Unlimited'),
    },
  ]

  const balanceColumns = [
    {
      header: t('Taken'),
      value: takenDays.value,
    },
    {
      header: t('Remaining'),
      value: isUnlimited.value ? t('Unlimited') : remainingDays.value,
    },
  ]
  if (props.showBalance) {
    columns.push(...balanceColumns)
  }
  return columns
})

const icon = computed(() => {
  const type = props.policy?.attributes.type as string
  return HolidayTypeIcons[type]
})

const rowActions = computed(() => {
  const defaultActions: RowAction[] = [
    {
      label: i18n.t('Edit'),
      icon: PencilSquareIcon,
      action: async () => {
        await router.push(`/settings/holidays/${props.policy?.id}/edit`)
      },
    },
    {
      label: i18n.t('Delete'),
      icon: TrashIcon,
      action: async () => {
        const confirmed = await $deleteConfirm({
          title: t('Delete Holiday Policy ?'),
          description: t(`Are you sure you want to delete this holiday policy ? Employees won't be able to request holidays using this policy from now on. This action cannot be undone.`),
          extraConfirmation: true,
        })
        const rowId = props.policy?.id
        const deleteUrl = `/restify/holiday-policies/${rowId}`
        if (!confirmed) {
          return
        }
        try {
          await axios.delete(deleteUrl)
        } catch (err: any) {
          if (err.handled) {
            return
          }
          error(i18n.t('Could not delete the selected policy'))
        }
      },
      show: (row: Data<HolidayPolicy>) => {
        return canDeleteRow(row)
      },
    },
  ]
  return defaultActions
})

watch(() => policyInitialBalance.value, (value) => {
  if (!employeeStore.currentEmployeeBalanceToUpdate && value && isVacation.value) {
    onBalanceUpdate(value)
  }
}, { immediate: true})
function onBalanceUpdate(value: number) {
  employeeStore.updatePolicyBalance({
    id: policyBalance.value?.id,
    ...(policyBalance.value?.attributes || {}),
    initial_balance: +value,
  })
}

async function onClick() {
  if (props.readOnly) {
    return
  }
  await router.push(`/settings/holidays/${props.policy?.id}/edit`)
}
</script>

<style>
.policy-initial-balance {
  margin-top: -10px;
}
.policy-initial-balance .form-control input {
  height: 46px;
}
</style>
