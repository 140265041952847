import { addMinutes, differenceInDays, isWeekend, parseISO } from "date-fns"
import LegalHoliday = App.Domains.LegalHolidays.Models.LegalHoliday
import Data = API.Data
import Holiday = App.Domains.Holidays.Models.Holiday
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

interface NationalHolidayParams {
  date: Date
  nationalHolidays: Data<LegalHoliday>[]
  year?: number
}

export function isNationalHoliday({ date, nationalHolidays = [] }: NationalHolidayParams) {
  date.setHours(0, 0, 0)
  return nationalHolidays.find((holiday) => {
    let holidayDate = parseISO(holiday.attributes.date)
    holidayDate = addMinutes(holidayDate, holidayDate.getTimezoneOffset())
    const daysDiff = differenceInDays(date, holidayDate)
    return daysDiff === 0
  }) !== undefined
}

export function differenceInWorkingDays(startDate: Date, endDate: Date, nationalHolidays: Data<LegalHoliday>[]) {
  let count = 0
  const date = startDate
  const settingsStore = useSettingsStore()
  const includeWeekends = settingsStore.getSetting(SettingKeys.IncludeHolidayWeekends)
  while (date <= endDate) {
    const isWeekendDay = isWeekend(date) && !includeWeekends
    if (!isWeekendDay && !isNationalHoliday({ date, nationalHolidays })) {
      count++
    }
    date.setDate(date.getDate() + 1)
  }
  return count
}

export function canEditHoliday(holiday?: Data<Holiday>): boolean {
  if (!holiday?.id) {
    return true
  }
  const { approved_at, rejected_at, cancelled_at } = holiday?.attributes || {}
  return approved_at === null && rejected_at === null && cancelled_at === null
}

export function isHolidayApproved(holiday?: Data<Holiday>): boolean {
  return holiday?.attributes?.approved_at !== null
}

export function isHolidayRejected(holiday?: Data<Holiday>): boolean {
  return holiday?.attributes?.rejected_at !== null
}

export function isHolidayCancelled(holiday?: Data<Holiday>): boolean {
  return holiday?.attributes?.cancelled_at !== null
}

export function isHolidayPending(holiday: Data<Holiday>): boolean {
  const { rejected_at, approved_at, cancelled_at } = holiday?.attributes || {}
  return rejected_at === null && approved_at === null && cancelled_at === null
}

export const ExportFormats = {
  Xlsx: 'xlsx',
  CSV: 'csv',
}
