<template>
  <div class="h-full flex flex-col data-page">
    <PageTitle class="mb-2">
      {{ $t('Company settings') }}
    </PageTitle>
    <BaseFormCard
      :submit-text="$t('Update')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
    >
      <FormCardSection :title="$t('Company details')">
        <FormKit
          v-model="model.company_name"
          name="company_name"
          type="text"
          :label="t('Company Name')"
          :placeholder="t('My Company')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.company_administrator"
          name="company_name"
          type="text"
          :label="t('Administrator')"
          :placeholder="t('John Doe')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.company_email"
          name="company_email"
          type="text"
          :label="t('Contact Email')"
          :placeholder="t('hello@company.com')"
          validation="email"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.company_registration_number"
          name="company_registration_number"
          type="text"
          :label="t('Register No.')"
          :placeholder="t('J12/1234/2018')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.company_identification_number"
          name="company_identification_number"
          type="text"
          :label="t('VAT Number')"
          :placeholder="t('RO32392318')"
          outer-class="col-span-6 md:col-span-2"
        />
        <div class="col-span-6 md:col-span-2" />

        <FormKit
          v-model="model.company_street_address"
          name="company_street_address"
          type="text"
          :label="t('Company street address')"
          :placeholder="t('Startdust Way 23')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.company_city"
          name="company_city"
          type="text"
          :label="t('City')"
          :placeholder="t('Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.company_county"
          name="company_county"
          type="text"
          :label="t('State')"
          :placeholder="t('Cluj')"
          outer-class="col-span-6 md:col-span-2"
        />
      </FormCardSection>
      <FormCardSection :title="$t('Settings')">
        <FormKit
          v-model="model.show_time_sheet_dates"
          type="checkbox"
          :label="t('Show time sheet dates')"
          :help="t('When enabled, it will display when users logged their time in the reports.')"
          outer-class="col-span-6 mt-2"
        />

        <FormKit
          v-model="model.holiday_include_weekends"
          type="checkbox"
          :label="t('Include weekends in holiday calculation')"
          :help="t('When enabled, the weekends will be included in the holiday calculation.')"
          outer-class="col-span-6 mt-6"
        />
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageBilling')"
        :title="$t('Delete company account')"
      >
        <div class="col-span-6 mt-4">
          <BaseButton
            type="button"
            outline
            @click="onDeleteAccount"
          >
            {{ t('Delete company account') }}
          </BaseButton>
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import axios from "axios"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { SettingKeys, SettingsKeyType } from "@/modules/auth/types/enums"
import { success } from "@/components/common/NotificationPlugin"
import { $confirm, $deleteConfirm } from "@/components/common/modal/modalPlugin"
import { useAuthStore } from "@/modules/auth/store/authStore"

const loading = ref(false)

const { t } = useI18n()
const model = ref<Record<string, any>>({
  show_time_sheet_dates: false,
  holiday_include_weekends: false,
  company_name: '',
  company_identification_number: '',
  company_registration_number: '',
  company_street_address: '',
  company_city: '',
  company_county: '',
  company_administrator: '',
  company_email: '',
})

const settingsStore = useSettingsStore()

const initialModel = ref()

const settingsMap: Record<string, SettingsKeyType> = {
  show_time_sheet_dates: SettingKeys.ShowTimesheetDates,
  holiday_include_weekends: SettingKeys.IncludeHolidayWeekends,
  company_name: SettingKeys.CompanyName,
  company_identification_number: SettingKeys.CompanyIdentificationNumber,
  company_registration_number: SettingKeys.CompanyRegNumber,
  company_street_address: SettingKeys.CompanyStreet,
  company_city: SettingKeys.CompanyCity,
  company_county: SettingKeys.CompanyState,
  company_administrator: SettingKeys.CompanyAdmin,
  company_email: SettingKeys.CompanyEmail,
}

function initModel() {
  for (const key in settingsMap) {
    model.value[key] = settingsStore.getSetting(settingsMap[key])
  }
  model.value.show_time_sheet_dates = settingsStore.getSettingBoolean(SettingKeys.ShowTimesheetDates)
  model.value.holiday_include_weekends = settingsStore.getSettingBoolean(SettingKeys.IncludeHolidayWeekends)
  initialModel.value = {
    ...model.value,
  }
}
watch(() => settingsStore.settings, initModel, { immediate: true })

async function onSubmit() {
  try {
    loading.value = true
    for (const key in settingsMap) {
      const prevValue = initialModel.value[key]
      const newValue = model.value[key]
      if (prevValue !== newValue) {
        await settingsStore.updateSetting(settingsMap[key], model.value[key])
      }
    }
    success(t('Company settings updated successfully'))
  } finally {
    loading.value = false
  }
}

const authStore = useAuthStore()
const router = useRouter()

async function onDeleteAccount() {
  const confirmed = await $confirm({
    title: t('Delete company account!'),
    description: t(`We will send a confirmation code via email which you will need to enter to delete the account. Are you sure you want to delete the account?`),
    buttonText: t('Delete'),
  })
  if (!confirmed) {
    return
  }
  await axios.post(`/tenant/send-delete-code`)
  await $confirm({
    title: t('Delete company account!'),
    description: t(`Delete company account description`),
    buttonText: t('Delete'),
    hasInput: true,
    inputType: 'otp',
    inputLabel: t('Enter confirmation code sent via email'),
    onResolve: async (data: any) => {
      const code = data.extraInputText
      await settingsStore.deleteTenant({
        code,
      })
      authStore.logout()
      await router.push('/login')
    },
  })
}
</script>

<route lang="yaml">
name: Company Settings
meta:
  permission: manageCompany
</route>
