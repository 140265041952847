<template>
  <BaseFormDialog
    class="timesheet-form"
    :class="{ 'is-edit': isEdit }"
    :loading="loading"
    :is-edit="isEdit"
    :submit-text="isEdit ? t('Update') : createText"
    :title="formTitle"
    width="50vw"
    v-bind="$attrs"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.project_id"
      :label="t('Project')"
      :employee-id="employeeId"
      type="projectSelect"
      validation="required"
      outer-class="col-span-6"
      clearable
      @update:modelValue="onProjectChange"
    />

    <FormKit
      v-model="model.task_id"
      :label="t('Task')"
      :disabled="!model.project_id"
      :options="projectTasks"
      type="taskSelect"
      validation="required"
      outer-class="col-span-6"
      clearable
      @update:modelValue="onTaskChange"
    />

    <FormKit
      v-model="model.description"
      type="textarea"
      :placeholder="t('Notes (optional)')"
      :name="t('Notes')"
      rows="4"
      validation="length:0,500"
      outer-class="col-span-4 mt-4"
      @update:modelValue="onDescriptionChange"
    />
    <div class="col-span-2 mt-4">
      <BaseTooltip
        :disabled="!workedMinutesDisabled"
        :content="$t('Stop timer to edit')"
      >
        <FormKit
          v-model="model.worked_minutes"
          :disabled="workedMinutesDisabled"
          input-class="!text-2xl"
          placeholder="0:00"
          validation="timeLog"
          :validation-messages="{
            timeLog: t('Time entry is invalid'),
          }"
        />
      </BaseTooltip>
      <div class="flex w-full justify-end items-center space-x-2 mt-2">
        <button
          type="button"
          tabindex="-1"
          class="btn w-[46px] h-[38px] p-0 flex items-center justify-center btn-outline text-gray-400 border-gray-300"
          @click="deleteTimeSheet"
        >
          <TrashIcon class="w-4 h-4" />
        </button>
        <FormKit
          v-model="model.date"
          type="customDatePicker"
          tabindex="-1"
          :clearable="false"
          :prefix-icon="CalendarIcon"
          outer-class="date-picker"
        />
      </div>
    </div>
  </BaseFormDialog>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { startCase } from 'lodash-es'
import { useStorage } from '@vueuse/core'
import { CalendarIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { success } from '@/components/common/NotificationPlugin'
import { useTimeSheetStore } from '@/modules/time/store/timeSheetStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { minutesToHours, parseHoursToMinutes } from '@/modules/common/utils/parseHours'
import { formatDate } from '@/modules/common/utils/dateUtils'
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet;
import Data = API.Data;
import { useTimesheetForm } from "@/modules/time/composables/useTimesheetForm";

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  date: {
    type: String,
    required: true,
  },
  employeeId: {
    type: String,
    required: true,
  },
  otherLogs: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmits(['save', 'close', 'delete'])
const { t } = useI18n()

const timeSheetStore = useTimeSheetStore()

const minutes = props.data?.attributes?.worked_minutes || 0
const formattedHours = minutesToHours(minutes)
const lastSelectedProject = useStorage<any>('lastSelectedProject', null)
const lastSelectedTask = useStorage<string>('lastSelectedTask', null)
const lastDescription = useStorage<string | null>('lastDescription', null)

const model = ref({
  id: props.data.id || undefined,
  project_id: props.data?.attributes?.project_id || lastSelectedProject.value || null,
  task_id: props.data?.attributes?.task_id || lastSelectedTask.value || null,
  description: props.data?.attributes?.description || lastDescription.value || '',
  date: props.date,
  worked_minutes: props.isEdit ? formattedHours : '',
})

const { onProjectChange, onTaskChange, projectTasks } = useTimesheetForm(model)

const loading = ref(false)

const formTitle = computed(() => {
  const keyword = props.isEdit ? t('Edit time entry for') : t('Create time entry for')
  const formattedDate = startCase(formatDate(model.value.date, 'EEEE, dd MMM'))
  return `${keyword} ${formattedDate}`
})

const createText = computed(() => {
  if (!model.value.worked_minutes) {
    return t('Start timer')
  }
  return t('Create')
})

const workedMinutesDisabled = computed(() => {
  if (!props.isEdit) {
    return false
  }
  const timerStarted = props.data?.attributes?.timer_started_at
  return timerStarted !== null
})

async function onSubmit() {
  try {
    loading.value = true
    const minutes = parseHoursToMinutes(model.value.worked_minutes)
    const data = {
      ...model.value,
      employee_id: props.employeeId,
      worked_minutes: minutes,
    }
    let timeSheet
    if (props.isEdit) {
      timeSheet = await timeSheetStore.updateTimeSheet(data)
    } else {
      timeSheet = await timeSheetStore.createTimeSheet(data)
      if (!timeSheet.attributes.worked_minutes) {
        await timeSheetStore.startTimer(timeSheet, props.otherLogs as Data<Timesheet>[])
      }
    }
    clearDescription()
    if (props.isEdit) {
      success(t('Time entry updated'))
    } else {
      success(t('Time entry created'))
    }
    emit('save', timeSheet)
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

function clearDescription() {
  localStorage.removeItem('lastDescription')
}
async function onCancel(cancelClicked = false) {
  if (!cancelClicked) {
    return
  }
  clearDescription()
}

async function deleteTimeSheet() {
  const deleted = await timeSheetStore.deleteTimeSheet(model.value.id)
  if (!deleted) {
    return
  }
  emit('delete', props.data)
}

function onDescriptionChange(description: string) {
  if (props.isEdit || lastDescription.value === description) {
    return
  }
  lastDescription.value = description
}

onMounted(() => {
  focusOnTextArea()
})

function focusOnTextArea() {
  if (!props.isEdit) {
    return
  }
  setTimeout(() => {
    const name = t('Notes')
    const textArea: any = document.querySelector(`.timesheet-form textarea[name="${name}"]`)
    if (textArea) {
      textArea?.focus()
      textArea.selectionStart = textArea.value.length
    }
  }, 100)
}
</script>

<style lang="scss">
.timesheet-form {
  .el-input__prefix-inner .el-input__icon {
    @apply text-gray-700;
  }
}
</style>
