<template>
  <div class="h-full flex flex-col account-page">
    <BaseFormCard
      :submit-text="t('Save')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
    >
      <FormCardSection class="h-full">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <Cog6ToothIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ t('Invoice settings') }}
          </h4>
          <h5 class="mb-3">
            {{ t('Information about your company, which will show up on all future invoices') }}
          </h5>

          <FormKit
            :model-value="invoiceAddress"
            type="textarea"
            :name="t('Address')"
            :label="t('Address')"
            :help="t('This address will be selected as the default from address for all invoices.')"
            :rows="6"
            validation="required"
            validation-visibility="dirty"
            outer-class="col-span-6 md:col-span-4 mt-2 mb-6"
            @update:model-value="updatedInvoiceAddress = $event"
          />

          <FileUpload
            accept="image/*"
            :model-value="invoiceLogo"
            :label="invoiceLogo ? $t('Change company logo') : $t('Upload company logo')"
            type="invoice_logo"
            @update:model-value="updatedLogo = $event"
          />
          <div class="formkit-help text-xs text-gray-500 leading-4 mt-4">
            {{ $t('Your logo will be displayed on the top left corner of invoices. For best pdf results, please make sure your logo image is at least 500 pixels wide. Logos can be JPG, SVG, or PNG images under 3MB in size.') }}
          </div>
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { Cog6ToothIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { success } from "@/components/common/NotificationPlugin"
import FileUpload from "@/components/form/FileUpload.vue";

const loading = ref(false)
const { user, tenant } = useAuth()
const { t } = useI18n()

const settingsStore = useSettingsStore()
const invoiceAddress = computed(() => {
  return settingsStore.getSetting(SettingKeys.InvoiceCompanyAddress) || ''
})

const invoiceLogo = computed(() => {
  return settingsStore.getSetting(SettingKeys.InvoiceLogo) || ''
})

const updatedInvoiceAddress = ref(invoiceAddress.value)
const updatedLogo = ref(invoiceLogo.value)

async function onSubmit() {
  try {
    loading.value = true
    await settingsStore.updateSetting(SettingKeys.InvoiceCompanyAddress, updatedInvoiceAddress.value)
    await settingsStore.updateSetting(SettingKeys.InvoiceLogo, updatedLogo.value)
    success(t('Invoice settings updated'))
  } finally {
    loading.value = false
  }
}
</script>

<route lang="yaml">
name: Invoice Settings
</route>
