<template>
  <div class="flex flex-col h-full">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ $t($route.name) }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs"/>
    <EmployeeHolidayOverview v-if="employeeId" :employee-id="employeeId" />
    <HolidaysTable :employee-id="employeeId" class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import EmployeeHolidayOverview from "@/modules/employees/components/EmployeeHolidayOverview.vue";
import HolidaysTable from "@/modules/holidays/components/HolidaysTable.vue";
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs";
const props = defineProps({
  employeeId: {
    type: String,
  },
})
const { activeTab, tabs } = useFilterTabs('holidays')
</script>

<route lang="yaml">
name: Holidays List
redirect:
  name: My Holidays
</route>
