import { defineStore } from 'pinia'
import axios from "axios"
import { orderBy } from "lodash-es"
import Cache from '@/modules/common/Cache'
import { error, success } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"
import Employee = App.Domains.Employees.Models.Employee
import Data = API.Data
import Invitation = App.Domains.Invitations.Models.Invitation
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useAuthStore } from "@/modules/auth/store/authStore"
import { useBillingStore } from "@/modules/settings/store/billingStore";
import { $confirm } from "@/components/common/modal/modalPlugin";
import { XCircleIcon } from "@heroicons/vue/24/outline";
import { trackEmployeeCreated, trackInvitationSent } from "@/util/analytics";

function getEmployeeInvitation(employee: Data<Employee>) {
  const invitations: Data<Invitation>[] = employee.relationships?.user?.relationships.invitations || []
  if (invitations.length === 0) {
    return
  }
  return invitations[0]
}

export function getEmployeeName(employee: Data<Employee>) {
  const { first_name, last_name } = employee.attributes || {}
  if (!first_name && !last_name) {
    return ''
  }
  return `${first_name} ${last_name}`
}

export const useEmployeeStore = defineStore('employee', {
  state: () => {
    return {
      allEmployees: [] as Data<Employee>[],
      allEmployeesLoading: false as boolean,
      currentEmployee: {} as any,
      currentEmployeeBalanceToUpdate: null as any,
      currentEmployeeLoading: false as boolean,
      currentEmployeeError: null as any,
      invitationSending: false as boolean,
      invitationResending: false as boolean,
      invitationCancelling: false as boolean,
      archiveLoading: false as boolean,
      restoreLoading: false as boolean,
      loadingGoals: false,
      goals: [] as any[],
    }
  },
  actions: {
    async getAllEmployees() {
      try {
        this.allEmployeesLoading = true
        const { data } = await axios.get(`/restify/employees`, {
          params: {
            related: 'position',
            perPage: 500,
            archived: false,
          },
        })
        this.allEmployees = orderBy(data, 'attributes.first_name')
      } finally {
        this.allEmployeesLoading = false
      }
    },
    async getArchivedEmployees() {
      try {
        this.allEmployeesLoading = true
        const { data } = await axios.get(`/restify/employees`, {
          params: {
            related: 'position',
            perPage: 500,
            archived: true,
          },
        })
        this.allEmployees = this.allEmployees.concat(orderBy(data, 'attributes.first_name'))
      } finally {
        this.allEmployeesLoading = false
      }
    },
    updatePolicyBalance(data: any) {
      this.currentEmployeeBalanceToUpdate = data
    },
    async getOwnEmployee() {
      const { userEmployeeId } = useAuth()
      if (!userEmployeeId.value) {
        return
      }
      await this.getEmployee(userEmployeeId.value)
    },
    async getEmployee(id: string, invalidateCache = false) {
      if (!id) {
        return
      }
      try {
        this.currentEmployeeLoading = true
        const { data } = await Cache.getRequest(`/restify/employees/${id}`, {
          invalidateCache,
          params: {
            related: 'position,user.invitations,user.roles,level',
          },
        })
        const role_id = data.relationships?.user?.relationships?.roles?.[0]?.attributes?.id
        this.currentEmployee = {
          ...(data?.attributes || {}),
          role_id,
          id: data?.id,
          relationships: data.relationships,
          meta: data.meta,
        }
        this.updateEmployeeInList(data)
        this.currentEmployeeError = null

        const settingsStore = useSettingsStore()
        await settingsStore.getHolidayBalances({
          employee_id: id,
        })
      } catch (err: any) {
        this.currentEmployeeError = err
      } finally {
        this.currentEmployeeLoading = false
      }
    },
    updateEmployeeInList(employee: Data<Employee>) {
      const index = this.allEmployees.findIndex(e => e.id === employee.id)
      if (index === -1) {
        return
      }
      this.allEmployees[index] = employee
    },
    resetCurrentEmployee() {
      this.currentEmployee = {}
    },
    async updateEmployee(data: any) {
      const initial_vacation_balance = this.currentEmployeeBalanceToUpdate?.initial_balance
      const response = await axios.put(`/restify/employees/${data.id}`, {
        ...data,
        initial_vacation_balance,
      })
      await this.updateCurrentHolidayBalance(data.id)
      await this.getEmployee(data.id)
    },
    async createEmployee(requestData: any) {
      const settings = useSettingsStore()
      const initial_vacation_balance = this.currentEmployeeBalanceToUpdate?.initial_balance
      const { data } = await axios.post(`/restify/employees`, {
        ...requestData,
        initial_vacation_balance,
      })
      await this.getEmployee(data.id)
      trackEmployeeCreated({ role: settings.getRoleName(requestData.role_id) })
    },
    async updateOwnEmployee(data: any) {
      const authStore = useAuthStore()
      const { userEmployeeId } = useAuth()
      await axios.post(`/restify/employees/${userEmployeeId.value}`, data)
      await this.getEmployee(userEmployeeId.value as string, true)
      await authStore.getProfile()
    },
    async updateCurrentHolidayBalance(employeeId: string | null) {
      const settingsStore = useSettingsStore()
      await settingsStore.getHolidayBalances({
        employee_id: employeeId,
      })
    },

    async sendInvitation({ email, user_id, employee_id }: any) {
      try {
        this.invitationSending = true
        await axios.post(`/restify/invitations`, {
          user_id,
          email,
        })
        await this.getEmployee(employee_id, true)
        success(i18n.t(`An invitation has been sent to`, { email }))
        trackInvitationSent()
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t(`Could not send the invitation via email`))
      } finally {
        this.invitationSending = false
      }
    },

    async resendInvitation(employee: Data<Employee>) {
      try {
        this.invitationResending = true
        const invitation = getEmployeeInvitation(employee)
        if (!invitation) {
          return
        }
        await axios.post(`/restify/invitations/${invitation.id}/actions?action=resend-invitation-email`)
        await this.getEmployee(employee.id as string, true)
        success(i18n.t(`An invitation has been sent to`, { email: employee.attributes.email }))
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t(`Could not send the invitation via email`))
      } finally {
        this.invitationResending = false
      }
    },

    async cancelInvitation(employee: Data<Employee>) {
      try {
        const confirmed = await $confirm({
          title: i18n.t('Cancel Invitation'),
          description: i18n.t(`The employee will be notified about this change. They will no longer be able to join your team`),
          buttonText: i18n.t('Yes, cancel'),
          icon: XCircleIcon,
        })
        if (!confirmed) {
          return
        }
        this.invitationCancelling = true
        const invitation = getEmployeeInvitation(employee)
        if (!invitation) {
          return
        }
        await axios.post(`/restify/invitations/${invitation.id}/actions?action=cancel-invitation-email`)
      } finally {
        this.invitationCancelling = false
      }
    },
    async archiveEmployee(employee: Data<Employee>) {
      try {
        const billingStore = useBillingStore()
        this.archiveLoading = true
        await axios.post(`/restify/employees/${employee.id}/actions?action=archive`)
        billingStore.onEmployeeArchived()
      } finally {
        this.archiveLoading = false
      }
    },
    async restoreEmployee(employee: Data<Employee>) {
      try {
        this.restoreLoading = true
        await axios.post(`/restify/employees/actions?action=unarchive`, {
          employee_id: employee.id,
        })
      } finally {
        this.restoreLoading = false
      }
    },
    async getEmployeeGoals(employeeId: string) {
      try {
        this.loadingGoals = true
        const { data } = await axios.get('/restify/goals', {
          params: {
            employee_id: employeeId,
          },
        })
        this.goals = orderBy(data, 'attributes.due_date')
      } finally {
        this.loadingGoals = false
      }
    },
  },
  getters: {
    currentEmployeeName(state) {
      const { first_name, last_name } = state.currentEmployee || {}
      if (!first_name && !last_name) {
        return ''
      }
      return `${first_name} ${last_name}`
    },
    getEmployeeNamesFromIds(state) {
      return (employeeIds: string[] | null, employeeData: any[] | null = null) => {
        if (!employeeIds) {
          return
        }
        const allEmployees = employeeData?.length ? employeeData : state.allEmployees || []
        const employees = allEmployees.filter(e => employeeIds.includes(e.id as string))
        return employees.map(getEmployeeName).join(', ')
      }
    },
    getEmployeeById(state) {
      return (id: string | null) => {
        return state.allEmployees.find(e => e.id === id)
      }
    },
  },
})
