<template>
  <div class="flex flex-col">
    <div v-if="showTabs" class="w-full flex justify-between mt-5">
      <PageTitle :show-bread-crumbs="false" :show-back="false" class="-mt-5">
        {{ t('Invoices (Beta)') }}
      </PageTitle>
      <div>
        <button
          class="btn btn-sm btn-primary"
          @click="goToInvoiceForm()"
        >
          <PlusIcon class="w-4 h-4 mr-1" />
          <span>{{ t('New invoice') }}</span>
        </button>
      </div>
    </div>
    <Tabs v-if="showTabs" class="mb-4" :tabs="tabs" />
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { PlusIcon } from '@heroicons/vue/24/outline'
import { can } from '@/plugins/permissionPlugin'
import { isBetaTestingCompany } from '@/modules/common/config'
import { useClientStore } from '@/modules/clients/store/clientStore'
import BaseContentCard from '@/components/common/BaseContentCard.vue'

const clientStore = useClientStore()
const { t } = useI18n()
const tabs = computed(() => {
  const tabs: any[] = [
    {
      label: t('Open'),
      path: '/invoices/open',
      show: can('manageInvoices'),
    },
    {
      label: t('All'),
      path: '/invoices/all',
      show: can('manageInvoices'),
    },
    {
      label: t('Configure'),
      path: '/invoices/configure',
      show: can('manageInvoices'),
    },
  ]
  return tabs.filter(tab => tab.show !== false)
})
const route = useRoute()
const showTabs = computed(() => tabs.value.some(tab => route.path.includes(tab.path)))
const router = useRouter()
function goToInvoiceForm() {
  router.push({ path: '/invoices/create' })
}

onMounted(async () => {
  await clientStore.getAllClients()
})
</script>

<route lang="yaml">
name: Invoices
redirect: /invoices/open
</route>
