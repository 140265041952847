import { computed, onMounted, ref } from "vue"
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { SettingKeys } from "@/modules/auth/types/enums"
import { error, success } from "@/components/common/NotificationPlugin"

export const Providers = {
  GoogleTenant: 'google-tenant',
  GoogleEmployee: 'google-employee',
}

export function useCalendarIntegration(provider: string, isPublicCalendar = false) {
  if (isPublicCalendar) {
    return {
      isCalendarConnected: true,
    }
  }
  const calendarsDataLoading = ref(false)
  const calendarsData = ref({
    calendars: [],
    redirectUrl: '',
    calendar_connected: false,
  })

  const settingsStore = useSettingsStore()
  const { t } = useI18n()

  async function getCalendarsData() {
    try {
      calendarsDataLoading.value = true
      const { data } = await axios.post(`/calendar/list-calendars`, {
        provider,
        returnUrl: window.location.href,
      })
      calendarsData.value = data
    } finally {
      calendarsDataLoading.value = false
    }
  }

  async function refreshSettings() {
    if (provider === Providers.GoogleTenant) {
      await settingsStore.getSettings(true)
    } else {
      await settingsStore.getUserSettings(true)
    }
  }
  async function getInitialData() {
    return Promise.all([
      getCalendarsData(),
      refreshSettings(),
    ])
  }

  async function onConnect() {
    window.open(calendarsData.value.redirectUrl)
  }

  async function disconnect() {
    try {
      await axios.post(`/calendar/disconnect`, {
        provider,
      })
      await refreshSettings()
      success(t('Calendar disconnected successfully'))
    } catch (err) {
      error(t('Failed to disconnect calendar'))
    }
  }

  async function connect(calendarId: string) {
    try {
      await axios.post(`/calendar/connect`, {
        provider,
        calendarId,
      })
      await refreshSettings()
      success(t('Calendar updated successfully'))
    } catch (err) {
      error(t('Failed to update calendar'))
    }
  }

  const isCalendarConnected = computed(() => {
    if (provider === Providers.GoogleTenant) {
      return settingsStore.getSetting(SettingKeys.GoogleCalendarRefreshToken)
    }
    return settingsStore.getUserSetting(SettingKeys.PersonalGoogleCalendarRefreshToken)
  })

  const selectedCalendar = computed(() => {
    if (provider === Providers.GoogleTenant) {
      return settingsStore.getSetting(SettingKeys.GoogleCalendarId)
    }
    return settingsStore.getUserSetting(SettingKeys.PersonalGoogleCalendarId) || ''
  })

  onMounted(async () => {
    await getInitialData()
  })

  return {
    calendarsDataLoading,
    calendarsData,
    selectedCalendar,
    isCalendarConnected,
    onConnect,
    disconnect,
    connect,
  }
}
