import differenceInDays from "date-fns/differenceInDays";
import { Invoice } from "@/modules/invoices/types/invoiceTypes";
import Data = API.Data;
import i18n from "@/i18n";

export function incrementInvoiceNumber(invoiceNumber: string): string {
  const parts = invoiceNumber.split('-');
  if (parts.length === 2 && /^[A-Z]{2}-\d{3}$/.test(invoiceNumber)) {
    const prefix = parts[0];
    const currentNumber = parseInt(parts[1], 10);
    const nextNumber = currentNumber + 1;
    const paddedNextNumber = nextNumber.toString().padStart(3, '0');
    return `${prefix}-${paddedNextNumber}`;
  } else {
    return ''
  }
}

export function getDueDays(date: string) {
  const parsedDate = new Date(date)
  return differenceInDays(parsedDate, new Date())
}

export function isInvoiceDue(invoice?: Data<Invoice> | null) {
  if (!invoice) {
    return false
  }
  if (invoice.attributes.paid_at) {
    return false
  }
  const dueDays = getDueDays(invoice.attributes.due_date)
  return dueDays < 0
}

export function getDueDaysText(date: string | undefined) {
  if (!date) {
    return
  }
  const daysDiff = getDueDays(date)
  if (!daysDiff || isNaN(daysDiff)) {
    return i18n.t('No due date')
  }
  if (daysDiff >= 0) {
    return i18n.t('Due in days', daysDiff)
  } else {
    return i18n.t('Due days ago', -daysDiff)
  }
}
