<template>
  <div class="flex items-center w-full space-x-5 file-icon-info cursor-pointer no-underline text-base-content"
       @click="onFileClick"
  >
    <FileIcon
      :icon-class="typeColors.text"
      :background-class="typeColors.background"
      :extension="extension"
    >
    </FileIcon>
    <div class="flex flex-1 flex-col leading-none text-sm truncate">
      <span class="font-semibold mb-0.5 truncate">
        {{ name }}
      </span>
      <div v-if="!isUrl" class="mt-1">
        <el-progress :stroke-width="2" :percentage="progress" color="--pc"/>
      </div>
      <span
        v-if="fileSize"
        class="text-base-300 text-xs -mt-4">
        {{ fileSize }}
      </span>
    </div>
    <div v-if="canRemove">
      <button
        class="bg-background hover:bg-base-200 h-5 w-5 rounded cursor-pointer flex items-center justify-center"
        @click.stop="emit('close')"
      >
        <XMarkIcon class="w-3 h-3"/>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getFileExtension, getFileTypeColor, getHumanFileSize } from "@/modules/documents/utils/documentUtils";
import FileIcon from './FileIcon.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { computed, PropType } from "vue";
import { downloadFileLocally } from "@/modules/common/utils/downloadFileLocally";

const props = defineProps({
  file: {
    type: Object as PropType<File | string>,
    default: () => ({})
  },
  fileName: {
    type: String,
  },
  progress: {
    type: Number,
    default: 0,
  },
  canRemove: {
    type: Boolean,
    default: true,
  }
})

const emit = defineEmits(['close'])
const name = computed(() => {
  if (typeof props.file === 'string') {
    let alternativeFileName = new URL(props.file).pathname.split('/').pop()
    return props.fileName || alternativeFileName
  }
  return props.file.name
})

const isUrl = computed(() => {
  return typeof props.file === 'string' && props.file.startsWith('http')
})
const typeColors = computed(() => {
  return getFileTypeColor(name.value)
})

const fileSize = computed(() => {
  if (typeof props.file === 'string') {
    return ''
  }
  return getHumanFileSize(props.file?.size)
})

const extension = computed(() => {
  return getFileExtension(name.value)
})

function onFileClick() {
  if (typeof props.file === 'string') {
    window.open(props.file, '_blank')
  } else {
    downloadFileLocally(props.file, name.value)
  }
}
</script>

<style>
.el-progress.el-progress--line {
  @apply flex flex-col w-full;
}

.el-progress__text,
.el-progress-bar {
  @apply w-full;
}

.el-progress__text {
  @apply flex justify-end mt-1 text-base-300;
}
</style>
